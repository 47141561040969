import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Navbar, NavbarBrand, Nav, Button} from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/auth';
import NavbarToggle from 'react-bootstrap/NavbarToggle';
import NavbarCollapse from 'react-bootstrap/NavbarCollapse';


export default class TeacherNavbar extends Component{

	constructor(props) {
		super(props);

		this.state = {
			authenticated: this.props.authenticated,
			type : this.props.type
		};

		this.logout = this.logout.bind(this);
	}

	componentDidUpdate(prevProps) {
		if(this.props.authenticated != prevProps.authenticated) {
			this.setState({
				authenticated: this.props.authenticated
			});
		}
		if(this.props.type != prevProps.type) {
			this.setState({
				type: this.props.type
			});
		}

	}

	render(){
		return(
			<Navbar bg="dark" expand="lg" variant="dark">
				<NavbarBrand>
					<NavbarLink to="/">Doc's Farm Portal</NavbarLink>
				</NavbarBrand>
				{this.renderNav()}
				{this.renderBugReport()}
				{this.renderLogout()}
			</Navbar>
		);
	}

	renderNav() {
		if(this.state.authenticated) {
			if(this.state.type == "Teacher"){
				return (
					<Nav className="mr-auto">
						<NavbarLink to="/">Classes</NavbarLink>
						<NavbarLink to="/students">Students</NavbarLink>
					</Nav>
				);
			}else if (this.state.type == "Parent"){
				return (
					<Nav className="mr-auto">
						<NavbarLink to="/dashboard">Dashboard</NavbarLink>
						<NavbarLink to="/students">My Students</NavbarLink>
					</Nav>
				);
			}

		}
	}

	renderLogout() {
		if(this.state.authenticated) {
			return <Button variant="danger" size="md" onClick={this.logout}>Logout</Button>
		}
	}

	renderBugReport() {
		return <a href="mailto:towsonAR@gmail.com"><Button style={{margin: "0 20px 0 0"}}>Report Bug</Button></a>
	}

	logout() {
		firebase.auth().signOut();
	}
}

class NavbarLink extends Component {
	render() {
		return (
			<Link to={this.props.to} className="nav-link" role="button">
				{this.props.children}
			</Link>
		);
	}
}
