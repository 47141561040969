import React, { Component,Text,Container } from 'react';

import {FormControl, FormLabel, FormGroup} from 'react-bootstrap';
export default class Privacy extends Component{
  constructor(props) {
		super(props);

	}


  render(){
    return(

      <FormGroup style = {{margin:50}}>
        <FormLabel><strong>Privacy Policy</strong></FormLabel>
        <FormLabel>Data about the user that is stored within our database consists of the user's first name, last name, email address to be used for log in, classes in the game of which the user is in, and the results of the user's responses to the questions they are assigned. All of the data except for user responses to questions is collected within this web portal and not in the mobile game. The only data collected in the mobile game are the results of questions answered by users, to include performance on specific questions, question sets, and question topics.  This data is only used within the app/game and by Towson University's research team for the study of the effect of AR on student performance. This data will never be shared with a third party with the exception of being stored in our Google Firebase Database. Please refer to Google's privacy policy regarding their Firebase products for further information on their privacy policy. User's data will never be monetized and if any changes are made to the use of User's data they will be notified by print or electronically. By using the mobile application "Doc's Farm" the user agrees with this policy and agrees to collection and use of the data described above by the Towson University Research Team. </FormLabel>
      </FormGroup>

    );
  }

}
