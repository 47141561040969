import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Modal, ModalTitle, ModalBody, ModalFooter, Button, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as firebase from 'firebase/app';
import "firebase/database";
import Controls from "../Controls/Controls";
import Loading from "../Loading/Loading";
import { Link } from "react-router-dom";
import * as moment from "moment";
import "./Attempts.css";
import ModalHeader from "react-bootstrap/ModalHeader";
import * as QueryString from "query-string"

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import Accordion from 'react-bootstrap/Accordion'
import { Card } from 'react-bootstrap'

library.add(faCheck, faTimes);

const { SearchBar } = Search;

class Attempts extends Component {

  constructor(props) {
    super(props);
    const params = QueryString.parse(props.location.search);
    this.teacherId = params.teacherId;

    this.state = {
      classId: this.props.match.params.classId,
      studentId: this.props.match.params.studentId,
      studentData: {},
      loading: true,
      modalOpen: false,
      selectedAttempt: {},
      selectedAttemptQuestions: []
    };

    // Load display names
    this.loadClassName().then(name => {
      this.className = name;
    });
    this.loadStudentName().then(name => {
      this.studentName = name;
    });
    this.getArrayOfQuestionSetNames().then(sets => {
      const filter = [];
      Object.keys(sets).forEach(key => {
        filter.push({id: key, name: sets[key]});
      });

      const sorted = {};
      filter.sort((a, b) => {
        if (a.name > b.name)
          return 1;
        if (a.name < b.name)
          return -1;
        return 0;
      }).forEach(questionSet => {
        sorted[questionSet.id] = questionSet.name;
      });

      this.questionSets = sorted;
    });

    this.loadStudentClassData = this.loadStudentClassData.bind(this);
    this.handleSelectAttempt = this.handleSelectAttempt.bind(this);
    this.handleShowAttemptModal = this.handleShowAttemptModal.bind(this);
    this.handleHideAttemptModal = this.handleHideAttemptModal.bind(this);
  }

  componentDidMount() {
    this.loadStudentClassData();
  }

  render(){
    const selectRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      style: { background: "#eee" },
      onSelect: this.handleSelectAttempt
    };

    const attemptColumns = [
      {
        dataField: "date",
        text: "Date",
        sort: true,
        formatter: (val, row, rowIndex) => {
          return <div>{moment.utc(val).local().format("M/D/YYYY \\a\\t hh:mm a")}</div>
        }
      },
      {
        dataField: "grade",
        text: "Grade",
        sort: true,
        formatter: (val, row, rowIndex) => {
          return <div>{val}%</div>
        }
      },
      {
        dataField: "correct",
        text: "Correct",
        sort: true,
        formatter: (val, row, rowIndex) => {
          return (
            <div>{val}</div>
          )
        }
      },
      {
        dataField: "questions.length",
        text: "Questions",
        sort: false,
        formatter: (val, row, rowIndex) => {
          return <div>{val}</div>
        }
      },
    ]

    if(this.state.loading) {
      return <Loading/>
    }

    if(!this.state.studentData || this.state.studentData.length === 0) {
      return (
        <Container className="page-content">
          <Controls title={"Attempts"}/>

          <div className="question-group" style={{marginBottom: "1rem"}}>
            <div>
              <strong>Student:</strong> {this.studentName}
            </div>
            <div>
              <strong>Class:</strong> {this.className}
            </div>
          </div>
          <div style={{textAlign: "center", marginTop: "3rem"}}>
            <h4>No Attempts</h4>
          </div>
        </Container>
      )
    }

    const studentAttempts = this.state.studentData;
    const setAttempts = this.renderQuestionSetAttempts(studentAttempts, attemptColumns, selectRow);

    return (
      <Container className="page-content">
        <div className="question-group" style={{marginBottom: "1rem"}}>
          <div>
            <strong>Student:</strong> {this.studentName}
          </div>
          <div>
            <strong>Class:</strong> {this.className}
          </div>
        </div>

        <h3 style={{"margin": "40px 0 10px 0"}}>Question Set Attempts</h3>
        <p style={{"marginBottom": "40px", "fontStyle": "italic"}}>Click on a table entry to bring up more information...</p>
        <Accordion>{setAttempts}</Accordion>

        <Modal show={this.state.modalOpen} onHide={this.handleHideAttemptModal} size="lg">
          <ModalHeader closeButton onHide={this.handleHideAttemptModal}>
            <ModalTitle>
              Attempt
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <h4>Attempt Statistics</h4>
            <div style={{marginBottom: ".5rem"}} className="question-group">
              <div>
                <strong>Date:</strong> {moment.utc(this.state.selectedAttempt.date).local().format("M/D/YYYY \\a\\t hh:mm a")}
              </div>
              <div>
                <strong>Grade:</strong> {this.state.selectedAttempt.grade}%
              </div>
              <div>
                <strong>Correct:</strong> {this.state.selectedAttempt.correct}
              </div>
              <div>
                <strong>Questions:</strong> {this.state.selectedAttempt.questions !== undefined ? this.state.selectedAttempt.questions.length : 0}
              </div>
            </div>
            <hr/>
            <h4>Questions:</h4>
            {this.renderSelectedAttemptModalBody()}
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={this.handleHideAttemptModal} size="sm">
              Close
            </Button>
          </ModalFooter>
        </Modal>

      </Container>
    );
  }

  renderQuestionSetAttempts(studentAttempts, attemptColumns, selectRow) {
    const sets = [];
    Object.keys(this.questionSets).forEach(key => {
      const setAttempts = studentAttempts.filter(attempt => attempt.questionSetID === key);

      sets.push(
        <Card key={key}>
          <Accordion.Toggle as={Card.Header} eventKey={`${key}`} onClick={() => {this.setState({selectedAttempt: {}});}}>
            <h4>{this.questionSets[key]}</h4>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={`${key}`}>
            <ToolkitProvider keyField="id" data={setAttempts} columns={attemptColumns} search sort bootstrap4>
              {
                props => (
                  <div style={{"margin": "20px"}}>
                    <SearchBar {...props.searchProps} />
                    <BootstrapTable
                      {...props.baseProps}
                      selectRow={selectRow}
                      ref={this.questionsTable}
                    />
                  </div>
                )
              }
            </ToolkitProvider>
          </Accordion.Collapse>
        </Card>
      );
    });
    return sets;
  }

  renderShowAttemptButton() {
    if(Object.keys(this.state.selectedAttempt).length > 0) {
      return <Button onClick={this.handleShowAttemptModal} size="sm">Show</Button>
    }
  }

  renderSelectedAttemptModalBody() {
    if(Object.keys(this.state.selectedAttempt).length > 0) {
      const questions = this.state.selectedAttemptQuestions;
      const result = [];
      let i = 1;

      questions.forEach(question => {
        result.push(<div key={i}>
          <FormGroup className="question-group">
            <FormLabel style={{margin: "0"}}>
              <h5 style={{display: "inline-block"}}>Question {i}</h5>
              {this.renderQuestionCorrect(question.questionId)}
            </FormLabel>
            <hr style={{marginTop: ".5rem", marginBottom: ".5rem"}}/>
              <div>
                <FormGroup>
                  <FormLabel><strong>Question Image</strong></FormLabel>
                  <div>
                    <img src={question.imageUrl} className="question-image-preview"/>
                  </div>
                </FormGroup>
                <FormGroup>
                  <FormLabel><strong>Question Solution</strong></FormLabel>
                  <div style={{marginLeft: "1rem"}}>
                    {question.solutions.map((solution, index) => {
                      if(question.selectedSolution === index) {
                        return (
                          <div key={index}>
                            <input type="radio" disabled checked/> <span style={{marginLeft: "5px"}}>{solution}</span>
                          </div>
                        );
                      }
                      return (
                        <div key={index}>
                          <input type="radio" disabled/> <span style={{marginLeft: "5px"}}>{solution}</span>
                        </div>
                      );
                    })}
                  </div>
                </FormGroup>
              </div>
          </FormGroup>
        </div>);
        i++;
      });

      return result;
    }
  }

  async getArrayOfQuestions(questionSet) {
    const db = firebase.database();
    const snap = await db.ref(`teachers/${this.teacherId}/classes/${this.state.classId}/questionSets/${questionSet}/questions`).once("value");
    const questionHash = snap.val();
    const result = [];
    Object.keys(questionHash).forEach(key => {
      result.push(questionHash[key]);
    })
    return result;
  }

  async getArrayOfQuestionSetNames() {
    const db = firebase.database();
    const questionSetsRef = await db.ref(`teachers/${this.teacherId}/classes/${this.state.classId}/questionSets`).once('value');
    const questionSetsHash = questionSetsRef.val();
    const result = {};
    Object.keys(questionSetsHash).forEach(key => {
      result[key] = questionSetsHash[key].title;
    });
    return result;
  }

  renderQuestionCorrect(questionId) {
    let correct = false;
    const questions = this.state.selectedAttempt.questions;
    questions.forEach(question => {
      if (questionId === question.questionID) {
        correct = question.correct;
      }
    });

    if(correct) {
      return <FontAwesomeIcon icon="check" size="1x" style={{marginLeft: ".3rem", marginBottom: "3px", color: "var(--success)"}}/>
    } else {
      return <FontAwesomeIcon icon="times" size="1x" style={{marginLeft: ".3rem", marginBottom: "1px", color: "var(--danger)"}}/>
    }
  }

  /**
   * Loads the student attempt data from the database
   */
  loadStudentClassData() {
    const db = firebase.database();
    const studentId = this.state.studentId;
    const studentRef = db.ref(`attempts`).orderByChild(`studentID`).equalTo(studentId);

    studentRef.on("value", snap => {
      const snapVal = snap.val();
      const result = [];
      if (snapVal) {
        Object.keys(snapVal).forEach((key, index) => {
          let val = snapVal[key];
          val.id = key;

          // Get grade for question set attempt
          let i = 0, correct = 0;
          if (val.questions) {
            val.questions.forEach(question => {
              i++;
              if (question.correct)
                correct++;
            });
          }
          val.grade = (correct / i) * 100;
          val.correct = correct;

          // Push value to result
          result.push(val);
        });
      }
      this.setState({
        loading: false,
        studentData: result
      });
    });
  }

  /**
   * Load class name from the database
   */
  async loadClassName() {
    const teachers = await (await firebase.database().ref(`teachers`).once("value")).val();
    let name = "";
    Object.keys(teachers).forEach((key, index) => {
      // _class is underscore because class is a protected word
      const _class = teachers[key].classes[this.state.classId];
      if (_class) {
        name = _class.name;
      }
    });
    return name;
  }

  /**
   * Loads student name from the database
   */
  async loadStudentName() {
    const student = await (await firebase.database().ref(`students/${this.state.studentId}`).once("value")).val();
    return student.displayName;
  }

  handleSelectAttempt(attempt) {
    this.getArrayOfQuestions(attempt.questionSetID).then(questions => {
      this.setState({
        selectedAttempt: attempt,
        selectedAttemptQuestions: questions
      });
      this.handleShowAttemptModal();
    });
  }

  handleShowAttemptModal() {
    this.setState({
      modalOpen: true
    });
  }

  handleHideAttemptModal() {
    this.setState({
      modalOpen: false
    });
  }
}

export default withRouter(Attempts);
