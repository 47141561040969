import React, { Component,Text } from 'react';
import {BrowserRouter as Router,Route, Switch, withRouter, Redirect} from 'react-router-dom';
import AddQuestions from './components/AddQuestions';
import SeeScores from './components/SeeScores';
import Login from "./components/Login/Login";
import "jquery/dist/jquery.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-table/dist/bootstrap-table.min.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './App.css';
import {Jumbotron, Container, Button, FormControl, FormLabel, FormGroup} from 'react-bootstrap';


import * as firebase from 'firebase/app';
import "firebase/app";
import "firebase/database";
import config from "./firebase.json";
import Navbar from './components/TeacherNavbar/TeacherNavbar';
import Classes from './components/Classes/Classes';
import Class from './components/Class/Class';
import Students from './components/Students/Students';
import Loading from './components/Loading/Loading';
import Attempts from './components/Attempts/Attempts';
import Child from './components/Parents/Child';
import StudentDetails from './components/Parents/Students/StudentDetails'
import ClassDetails from './components/Parents/Classes/ClassDetails'
import ClassData from './components/ClassData/ClassData'
import Help from "./Help"
import Privacy from "./Privacy"
class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
      userId: null,
      loading: true,
      authUser: null,
      newUser:false,
      type:null

    };

    this.handleAuthStateChanged = this.handleAuthStateChanged.bind(this);
    this.initializeFirebase = this.initializeFirebase.bind(this);
    this.initializeUser = this.initializeUser.bind(this);

    this.newUser = this.newUser.bind(this);

		this.emailField = React.createRef();
		this.nameField = React.createRef();
    this.phoneField = React.createRef();
    this.accountType = React.createRef();
  }

  componentDidMount() {
    this.initializeFirebase();
  }

  render() {
    return (
      <div className="App">
        <Navbar authenticated={this.state.authenticated} type={this.state.type}/>
        {this.renderApp()}
      </div>
    );
  }

  newUser(){
    const db = firebase.database();
    const userId = this.state.userId;

    if(this.accountType.current.value == "Teacher"){


      const teacherRef = db.ref(`teachers/${userId}`);

      teacherRef.set({
        name: this.nameField.current.value,
        email: firebase.auth().currentUser.email,
        phone: this.phoneField.current.value,
        uid: userId,
        type: "teacher"
      });
      this.setState({
        newUser:false,
        type:"Teacher"
      })
    }
    else {
      const parentRef = db.ref(`parents/${userId}`)

      parentRef.set({
        name: this.nameField.current.value,
        email: firebase.auth().currentUser.email,
        phone: this.phoneField.current.value,
        uid: userId,
        type: "parent"
      });
      this.setState({
        newUser:false,
        type:"Parent"
      })
    }


  }

  renderApp() {
    if(this.state.loading) {
      return <Loading/>
    }



    if(this.state.authenticated) {

      if(this.state.newUser){
        return(
    			<Container className="newUser-container">
    				<form className="newUser-form">
    					<h4 className="newUser-title">New User</h4>
    					<FormGroup>
    						<FormLabel><strong>Full Name</strong></FormLabel>
    						<FormControl type="text" ref={this.nameField} autoComplete="name"/>
    					</FormGroup>
              <FormGroup>
    						<FormLabel><strong>Phone Number</strong></FormLabel>
    						<FormControl type="number" ref={this.phoneField}/>
    					</FormGroup>
              <FormGroup controlId="accountType">
                <FormLabel>Account Type</FormLabel>
                <FormControl as="select" ref={this.accountType} multiple>
                  <option>Teacher</option>
                  <option>Parent</option>
                </FormControl>
              </FormGroup>

    					<div className="justify-children-end">
    						<Button onClick={this.newUser}>Finish Sign Up</Button>
    					</div>
    				</form>
    			</Container>
    		)
      }

        if(this.state.type == "Teacher"){
          return (
            <Switch>
              <Route path="/help" exact render={() => <Help/>}/>
              <Route path="/privacy" exact render={() => <Privacy/>}/>
              <Route path="/" exact render={() => <Classes userId={this.state.userId} />} />
              <Route path="/class/:classId" exact render={() => <Class userId={this.state.userId}/>}/>
              <Route path="/class/:classId/data" exact render={() => <ClassData userId={this.state.userId}/>}/>
              <Route path="/class/:classId/:studentId/attempts" exact render={() => <Attempts/>}/>
              <Route path="/students" exact render={() => <Students/>} />

              {/* 404 redirect */}
              <Redirect to="/"/>
            </Switch>
          );

        }if(this.state.type == "Parent"){
          return (
            <Switch>

              <Route path="/help" exact render={() => <Help/>}/>
              <Route path="/students" exact render={() => <Child userId={this.state.userId}/>/* Thing to render */} />
              <Route path="/students/:studentId" exact render={() => <StudentDetails userId={this.state.userId}/>/* Thing to render */} />
              <Route path="/students/:studentId/classes/:classId" exact render={() => <ClassDetails userId={this.state.userId}/>/* Thing to render */} />
              {/* 404 redirect */}
              <Redirect to="/students"/>
            </Switch>
          );
        }else {
          return(
            <Loading/>
          );
        }





    } else {
return(
      <Switch>
        <Route path="/help" exact render={() => <Help/>}/>
        <Route path="/privacy" exact render={() => <Privacy/>}/>
        <Route path="/" exact render={() => <Login/> } />


        {/* 404 redirect */}
        <Redirect to="/"/>
      </Switch>
)
    }
  }

  initializeFirebase() {
    firebase.initializeApp(config);

    firebase.auth().onAuthStateChanged(this.handleAuthStateChanged);

    this.setState({
      loading: false
    });
  }

  handleAuthStateChanged(user) {
    if(user) {
      this.initializeUser(user);
      this.setState({
        authenticated: true,
        userId: user.uid
      });
    } else {
      this.setState({
        authenticated: false,
        userId: null
      });
    }
  }

  initializeUser(user) {
    this.setState({
      loading: true
    }, () => {
      if(user) {
        const db = firebase.database();
        const userId = user.uid;

        const teacherRef = db.ref(`teachers/${userId}`);
        const studentRef = db.ref(`students/${userId}`);
        const parentRef = db.ref(`parents/${userId}`)

        studentRef.once("value", snap => {
          if(snap.exists()) {
            this.setState({
              loading: false
            }, () => firebase.auth().signOut());
          }
          else {
            teacherRef.once("value", (snap) => {
              if(snap.exists()) {
                this.setState({
                  type:"Teacher"
                })
              }
              else{
                parentRef.once("value", (snap)=> {
                  if(snap.exists()){
                    this.setState({

                      type:"Parent"
                    })

                  }
                  else{

                    this.setState({
                      newUser : true,
                    })
                  }



                })
              }

              this.setState({
                loading: false
              });
            });
          }




        });
      }
    });
  }
}

export default withRouter(App);
