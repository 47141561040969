import React, {Component,Text} from 'react';
import {BrowserRouter as Router,Route, Switch, withRouter, Redirect,Link} from 'react-router-dom';
import {Jumbotron, Container, Button, FormControl, FormGroup, FormLabel, Modal, ModalTitle, ModalBody, ModalFooter, Form} from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';
import './Classes.css';
import Loading from '../../Loading/Loading';
import ModalHeader from 'react-bootstrap/ModalHeader';
import Controls from "../../Controls/Controls";

const uuidv4 = require("uuid/v4");

class StudentDetails extends Component{

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			classes: [],
			userId: this.props.userId,
      studentId: this.props.match.params.studentId,
			firstName:"",
			lastName:""
		}

		this.loadClasses = this.loadClasses.bind(this);

	}

	componentDidMount() {
		this.loadClasses();
	}

	render(){
		if(this.state.loading) {
			return <Loading/>;
		}

		return(
			<Container className="page-content">
				<Controls title={this.state.firstName+" "+this.state.lastName+"'s "+"Dashboard"}>
					<Button size="sm" variant="success" >Help</Button>
				</Controls>
				<hr />
				<Form.Label><strong><big><big>Classes</big></big></strong></Form.Label>
				<div className="classes-body">
					{this.renderClasses()}
				</div>
				<hr />


			</Container>
		)
	}

	renderClasses() {
		const classes = this.state.classes;

		if(classes.length) {
			return classes;
		} else {
			return (
				<div className="no-classes">
					No classes added
				</div>
			);
		}
	}



	loadClasses() {
		const db = firebase.database();
		const userId = this.state.userId;

		const userRef = db.ref(`students/${this.state.studentId}`);

		const firstNameRef= userRef.child("firstName");
		const lastNameRef= userRef.child("lastName");


		firstNameRef.once("value").then((fName) => {
			lastNameRef.once("value").then((lName) => {
				this.setState({
					firstName: fName.val(),
					lastName: lName.val()

				});

			})

		})



		const classesRef = userRef.child("classes");

		classesRef.on("value", (snap) => {
			if(snap.exists()) {
				const classesData = snap.val();

				var classes = [];

				Object.keys(classesData).map(key => {
					const c = classesData[key];

					classes.push(
						<Link key={key} to={`/students/${this.state.studentId}/classes/${key}`} className="class-item">
							<strong>{c.name}</strong>
						</Link>
					);
				});

				this.setState({
					classes: classes,
					loading: false
				});
			} else {
				this.setState({
					loading: false
				});
			}
		});
	}


}
export default withRouter(StudentDetails);
