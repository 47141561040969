import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Jumbotron, Container, Button, FormControl, FormGroup, FormLabel, Modal, ModalTitle, ModalBody, ModalFooter, Form} from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';
import './Child.css';
import Loading from '../Loading/Loading';
import ModalHeader from 'react-bootstrap/ModalHeader';
import Controls from "../Controls/Controls";

const uuidv4 = require("uuid/v4");

export default class Child extends Component{

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			students: [],
			userId: this.props.userId,
			newClass: false
		}

		this.loadStudents = this.loadStudents.bind(this);


	}

	componentDidMount() {
		this.loadStudents();
	}

	render(){
		if(this.state.loading) {
			return <Loading/>;
		}

		return(
			<Container className="page-content">
				<Controls title="My Students">
					<Button size="sm" variant="success" >Request Student</Button>
				</Controls>
				<hr />
				<div className="student-body">
					{this.renderStudents()}
				</div>
			

			</Container>
		)
	}

	renderStudents() {
		const students = this.state.students;

		if(students.length) {
			return students;
		} else {
			return (
				<div className="no-students">
					No students added
				</div>
			);
		}
	}
  /*
	showNewClassForm() {
		this.setState({
			newClass: true
		});
	}

	hideNewClassForm() {
		this.setState({
			newClass: false
		});
	}
  */
	loadStudents() {  //////Good//////
		const db = firebase.database();
		const userId = this.state.userId;

		const userRef = db.ref(`parents/${userId}`);

		const studentRef = userRef.child("students");

		studentRef.on("value", (snap) => {
			if(snap.exists()) {
				const studentData = snap.val();

				var students = [];

				Object.keys(studentData).map(key => {
					const c = studentData[key];

					students.push(
						<Link key={key} to={`/students/${key}`} className="student-item">
							<strong>{c.firstName+" "+c.lastName}</strong>
						</Link>
					);
				});

				this.setState({
					students: students,
					loading: false
				});
			} else {
				this.setState({
					loading: false
				});
			}
		});
	}
  /*
	handleAddClass() {
		const className = this.classNameField.current.value;

		if(className.trim() != "") {
			this.addClass(className);
		} else {
			this.hideNewClassForm();
		}
	}

	addClass(className) {
		const db = firebase.database();
		const userId = this.state.userId;
		const classId = uuidv4();

		const classesRef = db.ref(`teachers/${userId}/classes`);


		classesRef.child(classId).set({
			name: className
		}).then(res => {
			this.hideNewClassForm();
		});
	}
  */
}
