import React, { Component,Text,Container } from 'react';

import {FormControl, FormLabel, FormGroup} from 'react-bootstrap';
export default class Help extends Component{
  constructor(props) {
		super(props);

	}


  render(){
    return(

      <FormGroup style = {{margin:50}}>
        <FormLabel>If you encounter any issues please email towsonAR@gmail.com and we will do our best to help.</FormLabel>
      </FormGroup>

    );
  }

}
