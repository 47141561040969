import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Container, Button,ButtonGroup,Dropdown,DropdownButton, Modal,InputGroup, ModalTitle, ModalBody, Form, FormGroup, FormControl, FormLabel, ModalFooter,ProgressBar,Card ,Table} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion'
import Controls from "../../Controls/Controls";
import { Link } from "react-router-dom";
import * as firebase from 'firebase/app';
import "firebase/database";
import "firebase/storage";
import ModalHeader from 'react-bootstrap/ModalHeader';
import jsPDF from 'jspdf';
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Loading from '../../Loading/Loading';
import html2canvas from 'html2canvas';
import 'katex/dist/katex.min.css';
import 'katex/dist/katex.min.js';
import renderMathInElement from 'katex/contrib/auto-render/auto-render.js';
import { InlineMath, BlockMath } from 'react-katex';
import MathQuill, { addStyles as addMathquillStyles } from 'react-mathquill'
import "./ClassQuestions.css";
import SolutionListItem from './SolutionListItem';
import { POINT_CONVERSION_COMPRESSED } from 'constants';
import TagsInput from 'react-tagsinput'
import DatePicker from "react-datepicker";
import './react-taginput.css'
import "react-datepicker/dist/react-datepicker.css";
import domtoimage from 'dom-to-image';
import katex from 'katex';
import htmlToImage from 'html-to-image';
const uuidv4 = require("uuid/v4");
var typeset = require("../../../../node_modules/mathjax-node-svg2png/lib/main.js").typeset;
var image=null;


addMathquillStyles()
library.add(faSpinner);

const { SearchBar } = Search;

const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');

export default class ClassQuestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      image: null,
      imageLoading: false,
      solutions: [],
      solutionOptions: [],
      selectedSolution: null,
      questions: [],
      questionId: null,
      moveButtonsDisabled: false,
      latex: '',
      questionText:"",
      typedImage:null,
      setModalOpen:false,
      questionSetName:null,
      setDueDate: null,
      numAttempts:null,
      gradingRule:null,
      tags:[],
      selecting:false,
      selectedRow:[],
      setEdit:false,
      questionIndex:null,
      copySet: false,
      copyQuestion: false,
      classes: [],
      sets: [],
      questionsSelect: [],
      copySelectedClass: null,
      copySelectedSet: null,
    };
    this.handleHideAddQuestionModal = this.handleHideAddQuestionModal.bind(this);
    this.handleShowAddQuestionModal = this.handleShowAddQuestionModal.bind(this);
    this.handleHideAddQuestionSetModal = this.handleHideAddQuestionSetModal.bind(this);
    this.handleShowAddQuestionSetModal = this.handleShowAddQuestionSetModal.bind(this);
    this.handleUploadPicture = this.handleUploadPicture.bind(this);
    this.handleUploadPictureTyped = this.handleUploadPictureTyped.bind(this);
    this.handleAddSolution = this.handleAddSolution.bind(this);
    this.handleAddSolutionOptions = this.handleAddSolutionOptions.bind(this);
    this.handleAddQuestion = this.handleAddQuestion.bind(this);
    this.handleAddQuestionSet = this.handleAddQuestionSet.bind(this);
    this.handleSelectQuestion = this.handleSelectQuestion.bind(this);
    this.removeSolutionItem = this.removeSolutionItem.bind(this);
    this.updateSolutionItem = this.updateSolutionItem.bind(this);
    this.removeSolutionItemOptions = this.removeSolutionItemOptions.bind(this);
    this.updateSolutionItemOptions = this.updateSolutionItemOptions.bind(this);
    this.loadQuestions = this.loadQuestions.bind(this);
    this.moveQuestionUp = this.moveQuestionUp.bind(this);
    this.moveQuestionDown = this.moveQuestionDown.bind(this);
    this.handleRemoveQuestion = this.handleRemoveQuestion.bind(this);
    this.handleRemoveQuestionSet = this.handleRemoveQuestionSet.bind(this);
    this.renderQuestionImage = this.renderQuestionImage.bind(this);
    this.renderLoadingImage = this.renderLoadingImage.bind(this);
    this.renderQuestionControls = this.renderQuestionControls.bind(this);
    this.renderQuestionSets = this.renderQuestionSets.bind(this);
    this.printDocument = this.printDocument.bind(this);
    this.getGradingRule = this.getGradingRule.bind(this);
    this.renderImageSolutions = this.renderImageSolutions.bind(this);
    this.fileUploadField = React.createRef();
    this.addSolutionField = React.createRef();
    this.addSolutionOptionsField = React.createRef();
    this.questionsTable = [];
    this.questionTextField = React.createRef();
    this.latexField = React.createRef();
    this.questionSetName = React.createRef();
    this.refsCreated = null;
    this.parseLatex = this.parseLatex.bind(this);
  }

  componentDidMount() {
    this.loadQuestions();
    this.loadTeacherClasses().then(result => {
      this.setState({classes: result});
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.studentsInClass !== prevProps.studentsInClass) {
      this.setState({
        studentsInClass: this.props.studentsInClass
      });
    }
  }

  render() {

    return (


      <div id="students-in-class">



      <Controls title="Question Sets">

        <Button variant="primary" size="sm" onClick={() => {this.handleShowAddQuestionSetModal()}}>Add Question Set</Button>
      </Controls>
      <Modal show={this.state.setModalOpen} onHide={this.handleHideAddQuestionSetModal}>
        <ModalHeader closeButton onHide={this.handleHideAddQuestionSetModal}>
          <ModalTitle>
            {this.state.setEdit && !this.state.copySet ? "Edit Question Set" : ""}
            {!this.state.setEdit && !this.state.copySet ? "Add a Question Set" : ""}
            {this.state.copySet ? "Copy a Question Set": ""}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <ButtonGroup aria-label="Basic example" style={{marginBottom: "20px"}}>
            <Button onClick={() => {
              this.setState({
                questionSetName: null,
                numAttempts: null,
                setDueDate: null,
                gradingRule: null,
                copySelectedClass: null,
                copySelectedSet: null,
                sets: [],
                copySet: false
              })}} variant="secondary" disabled={!this.state.copySet}>Create Set</Button>
            <Button onClick={() => {
              this.setState({
                questionSetName: null,
                numAttempts: null,
                setDueDate: null,
                gradingRule: null,
                copySelectedClass: null,
                copySelectedSet: null,
                sets: [],
                copySet: true
              })}} variant="secondary" disabled={this.state.copySet}>Copy Set</Button>
          </ButtonGroup>
          {this.state.copySet ? this.renderCopySetForm() : this.renderCreateSetForm()}
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={this.handleHideAddQuestionSetModal} size="sm">
            Close
        </Button>
          <Button type="submit" variant="primary" onClick={()=>{

            if (this.state.setEdit) {
              this.handleAddQuestionSet(this.state.currentSet);
            } else if (this.state.copySet) {
              this.handleCopyQuestionSet(this.state.copySelectedClass, this.state.copySelectedSet);
            } else {
              this.handleAddQuestionSet();
            }
          }} size="sm" disabled={(!this.state.questionSetName || !this.state.numAttempts || !this.state.setDueDate || !this.state.gradingRule) && (!this.state.copySelectedClass || !this.state.copySelectedSet)}>
            Confirm
        </Button>
        </ModalFooter>
      </Modal>
      {this.renderQuestionSets()}
      </div>
    );
  }

  renderCopySetForm() {
    return (<div>
      Choose where to copy a question set from:
      <FormGroup>
        <Form.Label>Class</Form.Label>
        <Form.Control value = {!this.state.copySelectedClass ? "" : this.state.copySelectedClass} as="select" onChange = {(event) => {
          const classId = event.target.value;
          this.loadTeacherQuestionSets(classId).then(sets => {
            this.setState({
              copySelectedClass: classId, sets});
            });
          }}>
            {this.renderCopySetClassOptions()}
        </Form.Control>
      </FormGroup>
      {this.renderCopySetQuestionSetSelect()}
    </div>);
  }

  renderCopySetQuestionSetSelect() {
    if (this.state.sets.length !== 0) {
      return (<FormGroup>
        <Form.Label>Question Set</Form.Label>
        <Form.Control value = {!this.state.copySelectedSet ? "" : this.state.copySelectedSet} as="select" onChange = {(event) => this.setState({copySelectedSet: event.target.value})} >
          {this.renderCopySetQuestionSetOptions()}
        </Form.Control>
      </FormGroup>);
    }
    return;
  }

  renderCopySetClassOptions() {
    const options = [];
    options.push(<option></option>);
    if (this.state.classes) {
      this.state.classes.forEach(c => {
        options.push(<option value={c.key}>{c.name}</option>);
      });
    }
    return options;
  }

  renderCopySetQuestionSetOptions() {
    const options = [];
    options.push(<option></option>);
    if (this.state.sets) {
      this.state.sets.forEach(set => {
        options.push(<option value={set.key}>{set.title}</option>);
      });
    }
    return options;
  }

  renderCopySetQuestionsOptions() {
    const options = [];
    options.push(<option></option>);
    if (this.state.questionsSelect) {
      let i = 1;
      this.state.questionsSelect.forEach(question => {
        options.push(<option value={question.key}>{i}</option>);
        i++;
      });
    }
    return options;
  }

  renderCreateSetForm() {
    return (<FormGroup>
            <Form>
              <FormGroup>
                <FormLabel>Enter Name of Question Set</FormLabel>
                <FormControl value = {!this.state.questionSetName ? "" : this.state.questionSetName} ref={this.questionSetName} onChange = {(event) => this.setState({questionSetName: event.target.value })} placeholder="Set Name"/>
              </FormGroup>
              <FormGroup>
                <div style={{display:"flex",flexDirection:"column",flexGrow: 1}}>
                <FormLabel>Enter Set Due Date</FormLabel>
                <DatePicker selected={!this.state.setDueDate ? null : this.state.setDueDate} onChange={(value)=>{
                  this.setState({
                    setDueDate:value
                  })
                }} />
                </div>
              </FormGroup>
              <FormGroup>
                <Form.Label>Number of Allowed Attempts For Set</Form.Label>
                <Form.Control value = {!this.state.numAttempts ? "" : this.state.numAttempts} as="select" onChange = {(event) => this.setState({numAttempts: event.target.value })} >
                  <option></option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>Unlimited</option>
                </Form.Control>
              </FormGroup>
              <FormGroup>
                <Form.Label>Grading Rule</Form.Label>
                <Form.Control value ={this.getGradingRule()} as="select" onChange = {(event) => this.setState({gradingRule: event.target.value.charAt(0) })} >
                  <option></option>
                  <option>1 - Most recent attempt is the recorded grade.</option>
                  <option>2 - Best attempt out of all attempts is recorded grade.</option>
                  <option>3 - For practice, not reflected in total grade. Score still shown.</option>
                </Form.Control>
              </FormGroup>
            </Form>
          </FormGroup>);
  }

  getGradingRule(element){
    if(element){

      if (element.gradingRule == 1) {
        return "1 - Most recent attempt is the recorded grade."
      }
      else if (element.gradingRule == 2) {
        return "2 - Best attempt out of all attempts is recorded grade."
      }
      else if (element.gradingRule == 3) {
        return "3 - For practice, not reflected in total grade. Score still shown."
      }
    }
    if(!this.state.gradingRule){
      return ""
    }
    else if (this.state.gradingRule == 1) {
      return "1 - Most recent attempt is the recorded grade."
    }
    else if (this.state.gradingRule == 2) {
      return "2 - Best attempt out of all attempts is recorded grade."
    }
    else if (this.state.gradingRule == 3) {
      return "3 - For practice, not reflected in total grade. Score still shown."
    }
    else{
      return ""
    }
  }

  renderImageSolutions(){
    var solutionList = []

    this.state.solutionOptions.map( x => {
      solutionList.push(<small><BlockMath style={{marginBottom:0}}>{"\\text{"+x+"}"}</BlockMath></small>)


    })


    return solutionList
  }

  parseLatex(latex){
    return latex.replace(/\\\\/g, "}\\\\\\text{");
  }

  async printDocument() {
    // localStorage.clear()
    this.setState({questionText:this.questionTextField.current.value})
    const input = await document.querySelector('#divToPrint')


    await html2canvas(input, {backgroundColor:null})
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        this.setState({typedImage:imgData});
        this.handleUploadPictureTyped();
      })
    ;

    // htmlToImage.toPng(input)
    //   .then((dataUrl) => {
    //     console.log(dataUrl);
    //     const imgData = dataUrl;
    //     this.setState({typedImage:imgData});
    //     this.handleUploadPictureTyped();
    //   })
    //   .catch(function (error) {
    //     console.error('oops, something went wrong!', error);
    //   });

    // domtoimage.toPng(this.QuestionImage.current)
    // .then (function (dataUrl) {
    //   var img = new Image();
    //   img.src = dataUrl;
    //   console.log(img);
    //   console.log(img.src);
    //   //this.setState({typedImage:imgData});
    //   //this.handleUploadPictureTyped();
    // })
    // .catch(function (error) {
    //     console.error('oops, something went wrong!', error);
    // });

    // var myCanvas = document.getElementById('QuestionImage');
    // var ctx = myCanvas.getContext('2d');
    // var img = new Image();
    // img.onload = function(){
    //   myCanvas.width = img.width;
    //   myCanvas.height = img.height;
    //
    //   ctx.drawImage(img, 0, 0);
    //
    //   console.log(myCanvas.toDataURL('image/jpeg'));
    // };
    //
    // //img.src = URL.createObjectURL(event.target.files[0]);
    // this.setState({typedImage:myCanvas.toDataURL('image/jpeg')});
  }

  renderQuestionSets(){


    var questionSetList = []
    let key = 0
    this.state.questions.forEach((element)=>{


      var questionList = []
      if(element.questions){

        for (const [key, value] of Object.entries(element.questions)) {
          questionList.push(value)
        }


      }
      const selectRow = {
        mode: "radio",
        style: { background: "#eee" },
        onSelect: (row, isSelect, rowIndex, e) => {this.handleSelectQuestion(row,rowIndex)},
        selected: this.state.selectedRow[key]
      };

      const questionColumns = [
        {
          dataField: "questionId",
          text: "Question",
          formatter: (val, row, rowIndex) => {
            return <div>{rowIndex+1}</div>
          }
        },
        {
          dataField: "imageUrl",
          text: "Image",
          formatter: (val, row, rowIndex) => {
            return <a style={{color: "#007bff"}} href={val} target="_blank">View Image</a>
          }
        },
        {
          dataField: "tags",
          text: "Content Tags",
          formatter: (val, row, rowIndex) => {
            if(val){
              return <div>{val.toString()}</div>
            }else{
              return <div>No Tags</div>
            }

          }
        },
        {
          dataField: "classPerformancePercentage",
          text: "Class Performance",
          formatter: (val, row, rowIndex) => {
            if(val == 0){
              return <div>N/A</div>
            }else{
              return <div>{`${val}%`}</div>
            }

          }
        }
      ];


      questionSetList.push(
        <Card key = {key}>
          <Accordion.Toggle as={Card.Header} eventKey={`${key}`} onClick={()=>{
            this.setState({currentSet:element.setID, selecting:false})

            this.questionsTable.forEach((item)=>{
              if(item){
                if(item.selectionContext){
                  item.selectionContext.selected = []
                }
              }
            })
          }}>
            <div className="flex-container">
              <div style={{display:"flex",flexDirection:"column",flexGrow: 1}}>
                <Card.Body >{`${element.title}`}</Card.Body>
              </div>
              <div style={{display:"flex",flexDirection:"column",flexGrow: 1, padding:20}}>

                  <Form.Label>Class Completion Percentage</Form.Label>


                <ProgressBar now={element.studentsCompleted ? (Object.keys(element.studentsCompleted).length/Object.keys(this.props.studentsInClass).length)*100 : 0 } label={`${element.studentsCompleted ? Math.ceil((Object.keys(element.studentsCompleted).length/Object.keys(this.props.studentsInClass).length)*100): " "}`} />
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={`${key}`}>
              <Card.Body >


              <div style={{display:"flex",flexDirection:"row",flexGrow: 1,justifyContent:"space-between"}}>
                <Button variant="primary" size="sm" onClick={this.handleShowAddQuestionModal}>Add Question</Button>
              <div style={{display:"flex",flexDirection:"row",flexGrow: 1,justifyContent:"flex-end"}}>
                <Button variant="primary"  size="sm" onClick={() => {this.handleShowAddQuestionSetModal(true, element)}}>Question Set Settings</Button>
                {this.state.selecting && this.state.currentSet == element.setID && this.renderQuestionControls(key, element.setID, element)}
                  <Button style = {{marginLeft:"20px"}}variant="danger" size="sm" onClick={()=>{
                    if(window.confirm("Are you sure you want to delete this question set?")){


                    this.handleRemoveQuestionSet(element.setID)}

                    }

                  }>Remove Set</Button>
              </div>
              </div>
              <hr/>
              <div style={{display:"flex",flexDirection:"column",flexGrow: 1}}>
                <Form.Label><strong>Question Set Settings</strong></Form.Label>
                <Form.Label>{`Due Date: ${new Date(element.dueDate).toLocaleString("en-US")}`}</Form.Label>
                <Form.Label>{`Grading Rule: ${this.getGradingRule(element)}`}</Form.Label>
                <Form.Label>{`Number of Attempts Allowed: ${element.numAttempts}`}</Form.Label>
              </div>
              <hr/>
              {element.questions && <ToolkitProvider
                keyField="questionId"
                data={questionList}
                columns={questionColumns}
                search
                sort
                bootstrap4
              >
                {
                  props => (
                    <div>
                      <SearchBar {...props.searchProps} />
                      <hr />
                      <BootstrapTable
                        {...props.baseProps}
                        selectRow={selectRow}
                        ref={(input)=>{
                          if(!(this.questionsTable.length >= key)){
                            this.questionsTable.push(input);
                          }else{
                            this.refsCreated=true
                          }

                        }}
                      />
                    </div>
                  )
                }
              </ToolkitProvider>}

              <Modal show={this.state.modalOpen && this.state.currentSet == element.setID} onHide={this.handleHideAddQuestionModal}>
                <ModalHeader closeButton onHide={this.handleHideAddQuestionModal}>
                  <ModalTitle>{this.state.copyQuestion ? "Copy a Question" : "Add a Question"}</ModalTitle>
                </ModalHeader>
                <ModalBody>

                <ButtonGroup aria-label="Basic example" style={{marginBottom: "20px"}}>
                  <Button onClick={() => {
                    this.setState({
                      image: null,
                      solutions: [],
                      solutionOptions: [],
                      selectedSolution: null,
                      copySelectedClass: null,
                      copySelectedSet: null,
                      copySelectedQuestion: null,
                      sets: [],
                      questionsSelect: [],
                      copyQuestion: false
                    })}} variant="secondary" disabled={!this.state.copyQuestion}>Add Question</Button>
                  <Button onClick={() => {
                    this.setState({
                      image: null,
                      solutions: [],
                      solutionOptions: [],
                      selectedSolution: null,
                      copySelectedClass: null,
                      copySelectedSet: null,
                      copySelectedQuestion: null,
                      sets: [],
                      questionsSelect: [],
                      copyQuestion: true
                    })}} variant="secondary" disabled={this.state.copyQuestion}>Copy Question</Button>
                </ButtonGroup>

                  {this.state.copyQuestion ? this.renderCopyQuestionForm() : this.renderCreateQuestionForm()}

                </ModalBody>
                <ModalFooter>
                  <Button variant="secondary" onClick={this.handleHideAddQuestionModal} size="sm">
                    Close
                </Button>
                  <Button type="submit" variant="primary" onClick={()=>{
                        this.state.copyQuestion ? this.handleCopyQuestion(this.state.copySelectedClass, this.state.copySelectedSet, this.state.copySelectedQuestion) : this.handleAddQuestion(element)
                      }}size="sm" disabled={(!this.state.image || this.state.solutions.length <= 0 || this.state.selectedSolution === null) && (!this.state.copySelectedClass || !this.state.copySelectedQuestion || !this.state.copySelectedSet)}>
                    Confirm
                </Button>
                </ModalFooter>
              </Modal>




              </Card.Body>
          </Accordion.Collapse>
        </Card>



      )
      key += 1
    })


    return(

    <Accordion>
      {questionSetList}
    </Accordion>);

  }

  renderCopyQuestionForm() {
    return (<div>
      Choose where to copy a question from:
      <FormGroup>
        <Form.Label>Class</Form.Label>
        <Form.Control value = {!this.state.copySelectedClass ? "" : this.state.copySelectedClass} as="select" onChange = {(event) => {
          const classId = event.target.value;
          this.loadTeacherQuestionSets(classId).then(sets => {
            this.setState({
              copySelectedClass: classId, sets});
            });
          }}>
            {this.renderCopySetClassOptions()}
        </Form.Control>
      </FormGroup>
      {this.renderCopyQuestionSetSelect()}
      {this.renderCopyQuestionPreview()}
    </div>);
  }

  renderCopyQuestionSetSelect() {
    if (this.state.sets.length !== 0) {
      return (<div>
          <FormGroup>
            <Form.Label>Question Set</Form.Label>
            <Form.Control value = {!this.state.copySelectedSet ? "" : this.state.copySelectedSet} as="select" onChange = {(event) => {
                const setId = event.target.value;
                this.loadTeacherQuestions(this.state.copySelectedClass, setId).then(questions => {
                  this.setState({copySelectedSet: setId, questionsSelect: questions});
                });
              }} >
              {this.renderCopySetQuestionSetOptions()}
            </Form.Control>
          </FormGroup>
          {this.renderCopySetQuestionSelect()}
        </div>);
    }
    return;
  }

  renderCopySetQuestionSelect() {
    if (this.state.questionsSelect.length !== 0) {
      return (<FormGroup>
        <Form.Label>Question Number</Form.Label>
        <Form.Control value={!this.state.copySelectedQuestion ? "" : this.state.copySelectedQuestion} as="select" onChange={(event) => this.setState({copySelectedQuestion: event.target.value})}>
          {this.renderCopySetQuestionsOptions()}
        </Form.Control>
      </FormGroup>)
    }
    return;
  }
  
  renderCopyQuestionPreview() {
    if (this.state.copySelectedClass && this.state.copySelectedSet && this.state.copySelectedQuestion) {
      const selected = this.state.questionsSelect.find(question => question.key === this.state.copySelectedQuestion);
      return (
        <FormGroup>
          <FormLabel><strong>Question Image:</strong></FormLabel>
          <div style={{margin: "0 auto", minWidth: "100%"}}>
            <img src={selected.url} className="question-image-preview"/>
          </div>
        </FormGroup>);
    }
    return;
  }

  renderCreateQuestionForm() {
    return (<div>
                  <FormGroup>
                    <FormLabel><strong>Upload Question Image</strong></FormLabel>
                    <div style={{display: "flex"}}>
                      <FormControl
                        multiple={false}
                        ref={this.fileUploadField}
                        type="file"
                        accept="image/x-png,image/jpeg"
                        required
                      />

                      {this.renderLoadingImage()}
                      <Button size="sm" onClick={this.handleUploadPicture}>Upload</Button>
                    </div>
                  </FormGroup>
                  <hr/>
                  <FormGroup>
                    <FormLabel><strong>Type In Question (LaTeX)</strong></FormLabel>
                    <Form>
                      <FormGroup>
                        <FormLabel>Enter Question Prompt</FormLabel>
                        <FormControl ref={this.questionTextField} onChange = {(event) => {
                        this.setState({questionText: event.target.value })

                        katex.render("\\text{"+this.parseLatex(event.target.value)+"}",document.getElementById('questionText') , {
                            displayMode:true,
                            throwOnError: false
                        })

                      }} placeholder="Prompt"/>

                      </FormGroup>
                    </Form>
                    <FormLabel>Enter Math Problem</FormLabel>
                    <FormControl ref={this.latexField} onChange = {(event) =>{
                      this.setState({latex: event.target.value })

                      katex.render("\\text{"+this.parseLatex(event.target.value)+"}",document.getElementById('subQuestionText') , {
                          displayMode:true,
                          throwOnError: false
                      })
                    }} placeholder=""/>
                    <FormGroup>
                      <FormLabel>Add Solutions To Prompt</FormLabel>
                        <InputGroup size="sm">
                          <FormControl type="text" ref={this.addSolutionOptionsField}/>
                          <InputGroup.Append>
                            <Button onClick={this.handleAddSolutionOptions} >Add Solution</Button>
                          </InputGroup.Append>
                        </InputGroup>
                        {this.renderSolutionOptions()}
                    </FormGroup>

                      <FormLabel>Question Preview</FormLabel>

                      <div id="divToPrint" style={{overflow:"auto"}}>
                        <div id="questionText">

                        </div>
                        <div id="subQuestionText">

                        </div>

                      <div style={{marginBottom:0}}>
                        {this.renderImageSolutions()}
                      </div>


                      </div>
                      <div className="text-right" >
                      <Button size="sm"  onClick={this.printDocument}>Upload</Button>
                      </div>
                  </FormGroup>

                  {this.renderQuestionImage()}
                  <hr/>
                  <FormGroup>
                    <FormLabel><strong>Add Solutions</strong></FormLabel>
                      <InputGroup size="sm">
                        <FormControl type="text" disabled={!this.state.image} ref={this.addSolutionField}/>
                        <InputGroup.Append>
                          <Button onClick={this.handleAddSolution} disabled={!this.state.image}>Add Solution</Button>
                        </InputGroup.Append>
                      </InputGroup>
                      {this.renderSolutions()}
                  </FormGroup>
                  <hr/>
                  <FormGroup>
                    <FormLabel><strong>Select Solution</strong></FormLabel>
                    {this.renderSolutionSelect()}
                  </FormGroup>
                  <hr/>
                  <FormGroup>
                    <FormLabel><strong>Enter Content Tags</strong></FormLabel>
                    <TagsInput value={this.state.tags} onChange={(tags)=>{this.setState({tags})}} />
                  </FormGroup>
    </div>);
  }

  renderQuestionControls(key, questionSetID) {
    //if(this.questionsTable[key].current && this.questionsTable[key].current.selectionContext.selected.length > 0) {
      if(this.state.questions.length > 1) {
        return (
          <div className="question-controls">
            {false && <Button className="mr-2" size="sm" onClick={()=>{this.moveQuestionUp(questionSetID)}} disabled={this.state.moveButtonsDisabled}>Up</Button>}
            {false && <Button className="mr-2" size="sm" onClick={()=>{this.moveQuestionDown(questionSetID)}} disabled={this.state.moveButtonsDisabled}>Down</Button>}
            <Button style = {{marginLeft:"20px"}} variant="danger" size="sm" onClick={()=>{
              if(window.confirm("Are you sure you want to delete this question?")){
                this.handleRemoveQuestion(key,questionSetID)
              }


              }}>Remove Question</Button>
          </div>

        );
      } else {
        return (
          <div className="question-controls">
            <Button style = {{marginLeft:"20px"}}  variant="danger" size="sm" onClick={()=>{

              if(window.confirm("Are you sure you want to delete this question?")){
                this.handleRemoveQuestion(key,questionSetID)
              }
          }}>Remove Question</Button>
          </div>
        );
      }
    //}
  }

  renderSolutionSelect() {
    const solutions = this.state.solutions;


    if(solutions && solutions.length) {
      var solutionRadioButtons = solutions.map((val, idx) => (
        <div key={idx}>
          <input
            type="radio"
            name="solution"
            value={val}
            checked={this.state.solutions[this.state.selectedSolution] === val}
            onChange={() => this.handleSelectSolution(idx)}
          /> {alphabet[idx]}
        </div>
      ));

      return (
        <div style={{marginLeft: "1rem"}}>
          {solutionRadioButtons}
        </div>
      );
    }

    return (
      <div style={{textAlign: "center"}}>
        <small>Upload an image, then add solutions</small>
      </div>
    );
  }

  renderSolutions() {
    if(this.state.solutions && this.state.solutions.length) {
      const solutions = this.state.solutions;
      var solutionListItems = solutions.map((val, idx) =>
        <SolutionListItem
          key={idx}
          index={idx}
          value={val}
          removeSolutionItem={this.removeSolutionItem}
          updateSolutionItem={this.updateSolutionItem}
        />);

      return (
        <FormGroup style={{marginLeft: "1rem", marginTop: ".5rem"}}>
          <FormLabel><small><strong>Possible Solutions</strong></small></FormLabel>
          <ol type="A">
            {solutionListItems}
          </ol>
        </FormGroup>
      );
    }

    return;
  }
  renderSolutionOptions() {
    if(this.state.solutionOptions && this.state.solutionOptions.length) {
      const solutions = this.state.solutionOptions;
      var solutionListItems = solutions.map((val, idx) =>
        <SolutionListItem
          key={idx}
          index={idx}
          value={val}
          removeSolutionItem={this.removeSolutionItemOptions}
          updateSolutionItem={this.updateSolutionItemOptions}
        />);

      return (
        <FormGroup style={{marginLeft: "1rem", marginTop: ".5rem"}}>
          <FormLabel><small><strong>Solution List</strong></small></FormLabel>
          <ol type="A">
            {solutionListItems}
          </ol>
        </FormGroup>
      );
    }
    return;
  }

  renderQuestionImage() {
    if(this.state.image && this.state.image.url) {
      return (
        <FormGroup>
        <hr/>
          <FormLabel><strong>Question Image:</strong></FormLabel>
          <div style={{margin: "0 auto", minWidth: "100%"}}>
            <img src={this.state.image.url} className="question-image-preview"/>
          </div>
        </FormGroup>
      );
    }
  }

  renderLoadingImage() {
    if(this.state.imageLoading) {
      return (
        <Loading size="1x" className="mr-3"/>
      );
    }
  }

  handleHideAddQuestionModal() {
    this.setState({
      modalOpen: false,
      image: null,
      solutions: [],
      selectedSolution: null,
      tags:[],
      questionText:"",
      typedImage:null,
      solutionOptions: [],
      latex:""
    });
  }

  handleShowAddQuestionModal() {
    this.setState({
      modalOpen: true
    })
  }

  handleShowAddQuestionSetModal(edit, data){
    if(edit){

      this.setState({
        setModalOpen: true,
        setEdit:true,
        questionSetName:data.title,
        setDueDate:new Date(data.dueDate),
        numAttempts:data.numAttempts,
        gradingRule:data.gradingRule,
      })
    }else{
      this.setState({
        setModalOpen: true
      })
    }

  }
  handleHideAddQuestionSetModal() {
    this.setState({
      setModalOpen: false,
      questionSetName:null,
      setDueDate:null,
      numAttempts:null,
      gradingRule:null,
      setEdit:false
    });
  }

  handleAddSolution() {
    const solution = this.addSolutionField.current.value;
    this.addSolutionField.current.value = "";
    this.addSolutionField.current.focus();
    var solutions = this.state.solutions.map(val => val);

    if(solution && solutions) {
      if(!solutions.includes(solution)) {
        solutions.push(solution);
        this.setState({
          solutions: solutions
        });
      }
    }
  }
  handleAddSolutionOptions() {
    const solution = this.addSolutionOptionsField.current.value;
    this.addSolutionOptionsField.current.value = "";
    this.addSolutionOptionsField.current.focus();
    var solutions = this.state.solutionOptions.map(val => val);

    if(solution && solutions) {
      if(!solutions.includes(solution)) {
        solutions.push(solution);
        this.setState({
          solutionOptions: solutions
        });
      }
    }
  }

  handleUploadPicture() {
    const image = this.fileUploadField.current.files;

    if(image && image[0]) {
      this.setState({
        imageLoading: true
      }, () => {
        const imageFile = image[0];


        const metadata = {
          contentType: imageFile.type
        };

        const classId = this.props.classId;

        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(`classes/${classId}/${imageFile.name}`);

        imageRef.put(imageFile, metadata).then(snap => {
          snap.ref.getDownloadURL().then(url => {
            this.setState({
              image: {
                file: imageFile,
                url: URL.createObjectURL(imageFile),
                public_url: url
              },
              imageLoading: false
            });
          });
        });
      });

    }
  }
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }



  handleUploadPictureTyped() {
    const image = this.state.typedImage;

    if(image) {
      this.setState({
        imageLoading: true
      }, () => {

        const imageFile=this.dataURLtoFile(image,uuidv4()+".jpg");

        const metadata = {
          contentType: imageFile.type
        };

        const classId = this.props.classId;

        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(`classes/${classId}/${imageFile.name}`);

        imageRef.put(imageFile, metadata).then(snap => {
          snap.ref.getDownloadURL().then(url => {
            this.setState({
              image: {
                file: imageFile,
                url: URL.createObjectURL(imageFile),
                public_url: url
              },
              imageLoading: false
            });
          });
        });
      });

    }
  }
  handleSelectSolution(index) {
    this.setState({
      selectedSolution: index
    });
  }

  updateSolutionItem(index, value) {
    var solutions = this.state.solutions.map(val => val);
    solutions[index] = value;
    this.setState({
      solutions: solutions
    });
  }
  updateSolutionItemOptions(index, value) {
    var solutions = this.state.solutionOptions.map(val => val);
    solutions[index] = value;
    this.setState({
      solutionOptions: solutions
    });
  }

  removeSolutionItem(index) {
    var solutions = this.state.solutions.map(val => val);
    solutions.splice(index, 1);
    this.setState({
      solutions: solutions
    }, () => {
      if(this.state.selectedSolution === index) {
        this.setState({
          selectedSolution: null
        });
      }
    });
  }
  removeSolutionItemOptions(index) {
    var solutions = this.state.solutionOptions.map(val => val);
    solutions.splice(index, 1);
    this.setState({
      solutionOptions: solutions
    });
  }

  handleAddQuestion(element) { //TO DO
    const db = firebase.database();
    const userId = this.props.userId;
    const classId = this.props.classId;
    var questionId;

    questionId = uuidv4();
    const questionIndex = element.questions ? element.questions.length+1 : 0
    const questionRef = db.ref(`teachers/${userId}/classes/${classId}/questionSets/${this.state.currentSet}/questions`);
    if(this.state.image != null && this.state.solutions.length >= 0 && this.state.selectedSolution != null) {
      const questionData = {
        //order: questionIndex,
        imageName: this.state.image.file.name,
        imageUrl: this.state.image.public_url,
        solutions: this.state.solutions,
        selectedSolution: this.state.selectedSolution,
        questionId: questionId,
        classPerformancePercentage:0,
        tags:this.state.tags
      };

      questionRef.child(questionId).set(questionData).then(this.handleHideAddQuestionModal);
    }

  }

  handleCopyQuestion(classId, setId, questionId) {
    const db = firebase.database();
    const userId = this.props.userId;
    const questionRef = db.ref(`teachers/${userId}/classes/${classId}/questionSets/${setId}/questions/${questionId}`);

    questionRef.on('value', (snap) => {
      const val = snap.val();
      const newId = uuidv4();
      val.questionId = newId;
      val.classPerformancePercentage = 0;
      
      const copyQuestionRef = db.ref(`teachers/${userId}/classes/${this.props.classId}/questionSets/${this.state.currentSet}/questions/${newId}`);
      copyQuestionRef.set(val);
      this.setState({
        modalOpen: false,
        copyQuestion: false,
        sets: [],
        questionsSelect: [],
        copySelectedClass: null,
        copySelectedSet: null,
        copySelectedQuestion: null
      })
    });
  }

  handleAddQuestionSet(id) {
    const db = firebase.database();
    const userId = this.props.userId;
    const classId = this.props.classId;

    if(!id){
      var questionSetId;

      questionSetId = uuidv4();

      const questionSetRef = db.ref(`teachers/${userId}/classes/${classId}/questionSets`);
      if(this.state.questionSetName != null && this.state.setDueDate != null && this.state.numAttempts != null && this.state.gradingRule != null) {

        const questionSetData = {
          title:this.state.questionSetName,
          dueDate:this.state.setDueDate.toString(),
          numAttempts:this.state.numAttempts,
          classPerformancePercentage:0,
          classCompletionPercentage:0,
          gradingRule:this.state.gradingRule,
          setID: questionSetId,
          classPointTotal:0,
          setPointValue:10
        };
        questionSetRef.child(questionSetId).set(questionSetData).then(this.handleHideAddQuestionSetModal);
      }

    }else{
      const questionSetRef = db.ref(`teachers/${userId}/classes/${classId}/questionSets/${id}/`);
      if(this.state.questionSetName != null && this.state.setDueDate != null && this.state.numAttempts != null && this.state.gradingRule != null) {

        const questionSetData = {
          title:this.state.questionSetName,
          dueDate:this.state.setDueDate.toString(),
          numAttempts:this.state.numAttempts,
          gradingRule:this.state.gradingRule,
        };
        questionSetRef.update(questionSetData).then(this.handleHideAddQuestionSetModal);
    }


  }
}

  handleCopyQuestionSet(classId, setId) {
    const db = firebase.database();
    const userId = this.props.userId;
    const questionSetRef = db.ref(`teachers/${userId}/classes/${classId}/questionSets/${setId}`);

    questionSetRef.on('value', (snap) => {
      const val = snap.val();
      const newId = uuidv4();
      val.setID = newId;
      val.classCompletionPercentage = 0;
      val.classPerformancePercentage = 0;
      val.classPointTotal = 0;
      val.studentsCompleted = {};
      
      const copyQuestionSetRef = db.ref(`teachers/${userId}/classes/${this.props.classId}/questionSets/${newId}`);
      copyQuestionSetRef.set(val);
      this.setState({
        setModalOpen: false,
        copySet: false,
        sets: [],
        copySelectedClass: null,
        copySelectedSet: null,
      })
    });
  }

  handleRemoveQuestionSet(setID){
    const db = firebase.database();
    const userId = this.props.userId;
    const classId = this.props.classId;

    const questionsRef = db.ref(`teachers/${userId}/classes/${classId}/questionSets/${setID}`);

    questionsRef.remove();

    this.setState({currentSet:null})
  }
  handleRemoveQuestion(key,setID) {

    var selectedIndex = this.questionsTable[key].selectionContext.selected[0];
    var questionID = this.questionsTable[key].table.getData()[this.state.questionIndex].questionId;



    const db = firebase.database();
    const userId = this.props.userId;
    const classId = this.props.classId;
    const questionsRef = db.ref(`teachers/${userId}/classes/${classId}/questionSets/${setID}/questions/${questionID}`);

    questionsRef.remove();

    this.setState({questionIndex:null, selecting:false})
  }

  handleSelectQuestion(question,index) {

    this.setState({
      questionId: question.id,
      selecting:true,
      questionIndex:index
    });
  }

  loadQuestions() {
    const db = firebase.database();
    const userId = this.props.userId;
    const classId = this.props.classId;
    const questionsRef = db.ref(`teachers/${userId}/classes/${classId}/questionSets`);

    questionsRef.on("value", snap => {
      if(snap.exists()) {
        const data = snap.val();

        var questions = Object.keys(data).map((val, idx) => {
          var obj = data[val];
          obj.id = val;
          return obj;
        });


        this.setState({
          questions: questions
        });
      }
    });
  }

  moveQuestionUp(setID) {
    var questions = this.state.questions.map(val => val);
    var selectedIndex = this.questionsTable.current.selectionContext.selected[0];
    if(selectedIndex > 1) {
      this.setState({
        moveButtonsDisabled: true
      }, () =>{

        this.questionsTable.current.selectionContext.selected = [selectedIndex-1];

          var questionData = {}
          questions.map((question, idx) => {
            idx += 1; // Normalize this index?

            if(question.order === selectedIndex) {
              question.order -= 1;
            } else if(question.order === selectedIndex-1) {
              question.order = selectedIndex;
            }

            questionData[question.id] = {
              imageName: question.imageName,
              imageUrl: question.imageUrl,
              order: question.order,
              selectedSolution: question.selectedSolution,
              solutions: question.solutions
            };
          });

          this.setState({
            questions: questions
          }, () => {
            const db = firebase.database();
            const userId = this.props.userId;
            const classId = this.props.classId;

            const questionsRef = db.ref(`teachers/${userId}/classes/${classId}/questions`);

            questionsRef.set(questionData).then(() => this.setState({
              moveButtonsDisabled: false
            }));
          })
      });
    }
  }

  moveQuestionDown(setID) {
    var selectedIndex = this.questionsTable.current.selectionContext.selected[0];
    var questions = this.state.questions.map(val => val);

    if(selectedIndex < questions.length) {
      this.setState({
        moveButtonsDisabled: true
      }, () =>{
        this.questionsTable.current.selectionContext.selected = [selectedIndex+1];


          var questionData = {}
          questions.map((question, idx) => {
            idx += 1; // Normalize this index?

            if(question.order === selectedIndex) {
              question.order += 1;
            } else if(question.order === selectedIndex+1) {
              question.order = selectedIndex;
            }

            questionData[question.id] = {
              imageName: question.imageName,
              imageUrl: question.imageUrl,
              order: question.order,
              selectedSolution: question.selectedSolution,
              solutions: question.solutions
            };
          });

          this.setState({
            questions: questions
          }, () => {
            const db = firebase.database();
            const userId = this.props.userId;
            const classId = this.props.classId;

            const questionsRef = db.ref(`teachers/${userId}/classes/${classId}/questions`);

            questionsRef.set(questionData).then(() => this.setState({
              moveButtonsDisabled: false
            }));
          });
      });
    }
  }

  async loadTeacherClasses() {
    const db = firebase.database();
    const classRef = db.ref(`teachers/${this.props.userId}/classes`);
    const snap = await classRef.once('value');
    const val = snap.val();

    const classes = [];
    Object.keys(val).forEach(key => {
      classes.push({key, name: val[key].name});
    });
    return classes;
  }

  async loadTeacherQuestionSets(classId) {
    const db = firebase.database();
    const questionSetRef = db.ref(`teachers/${this.props.userId}/classes/${classId}`);
    const snap = await questionSetRef.once('value');
    const val = snap.val();

    const sets = [];
    Object.keys(val.questionSets).forEach(key => {
      sets.push({key, title: val.questionSets[key].title});
    });
    return sets;
  }

  async loadTeacherQuestions(classId, setId) {
    const db = firebase.database();
    const questionSetRef = db.ref(`teachers/${this.props.userId}/classes/${classId}/questionSets/${setId}`);
    const snap = await questionSetRef.once('value');
    const val = snap.val();

    const questions = [];
    Object.keys(val.questions).forEach(key => {
      questions.push({key, url: val.questions[key].imageUrl});
    });
    return questions;
  }
}
