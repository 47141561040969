import React, { Component } from 'react';
import {BrowserRouter as Router,Route, Switch, withRouter, Redirect} from 'react-router-dom';
import * as firebase from 'firebase/app';
import "firebase/database";
import { Container, Button, Modal, ModalTitle, ModalBody, Form, FormGroup, FormControl, FormLabel, ModalFooter,ProgressBar, Accordion,Card ,Table} from 'react-bootstrap';
import Loading from '../../Loading/Loading';
import Controls from "../../Controls/Controls";
import ModalHeader from 'react-bootstrap/ModalHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ClassStudents from '../../Class/ClassStudents/ClassStudents';
import ClassQuestions from '../../Class/ClassQuestions/ClassQuestions';
import "./ClassDetails.css"
const { SearchBar } = Search;

class ClassDetails extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      userId: this.props.userId,
      studentData: null,
      studentId:this.props.match.params.studentId,
      classId: this.props.match.params.classId,
      classData: {},
      questionProgress:50,
      classGrade:80
    }

    this.loadClassData = this.loadClassData.bind(this);
    this.loadStudent = this.loadStudent.bind(this);
    this.renderQuestionSet = this.renderQuestionSet.bind(this);
    this.renderStatisticsTable = this.renderStatisticsTable.bind(this);
  }

  componentDidMount() {
    this.loadClassData();
    this.loadStudent();
  }

  render() {
    if(this.state.loading) {
      return <Loading/>
    }

    return (
      <Container className="page-content">
        <h2>{this.state.classData.name}</h2>
        <hr/>
        <FormGroup>
          <div class="flex-container">
            <div style={{display:"flex",flexGrow: 1,flexDirection:"column"}}>
              <Form.Label><big>{this.state.studentData ? this.state.studentData.firstName+" "+this.state.studentData.lastName : ""}</big></Form.Label>
              <Form.Label><big>{"Class Grade: "+this.state.classGrade}</big></Form.Label>
            </div>
            <div style={{display:"flex",flexDirection:"column",flexGrow: 1}}>
              <Form.Label><big>Question Progress</big></Form.Label>
              <ProgressBar now={this.state.questionProgress} label={`${this.state.questionProgress}%`} />
            </div>
          </div>
        </FormGroup>


        <hr/>
        <Controls title="Question Sets">

				</Controls>
        {this.renderQuestionSet()}
        <hr/>
        <Controls title="Statistics">

				</Controls>
        {this.renderStatisticsTable()}

      </Container>
    );
  }


  renderStatisticsTable(){
    return (
    <div style={{display:"flex",flexDirection:"row",marginBottom:20}}>
      <div style={{display:"flex",flexDirection:"column",flexGrow: 1, marginRight:20}}>
        <Form.Label><strong><big>Areas Of Strength</big></strong></Form.Label>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Content</th>
              <th>Success Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Fractions</td>
              <td>80%</td>
            </tr>
            <tr>
              <td>Multiplication</td>
              <td>73%</td>
            </tr>
            <tr>
              <td>Division</td>
              <td>68%</td>
            </tr>
          </tbody>

        </Table>
        </div>
        <div style={{display:"flex",flexDirection:"column",flexGrow: 1, marginLeft:20}}>
          <Form.Label><strong><big>Areas Of Weakness</big></strong></Form.Label>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Content</th>
                <th>Success Percentage</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Long Division</td>
                <td>30%</td>
              </tr>
              <tr>
                <td>Factorization</td>
                <td>38%</td>
              </tr>
              <tr>
                <td>Roots</td>
                <td>41%</td>
              </tr>
            </tbody>

          </Table>
          </div>
      </div>
    );
  }
  renderQuestionSet(){
    return (
      <Accordion defaultActiveKey="0">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <div class="flex-container">
              <div style={{display:"flex",flexDirection:"column",flexGrow: 1}}>
                <Card.Body >Week 1</Card.Body>
              </div>
              <div style={{display:"flex",flexDirection:"column",flexGrow: 1, padding:20}}>
                <Form.Label>Percentage Complete</Form.Label>
                <ProgressBar now={this.state.questionProgress} label={`${this.state.questionProgress}%`} />
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
              <Card.Body >Question info to be added</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            <div class="flex-container">
              <div style={{display:"flex",flexDirection:"column",flexGrow: 1}}>
                <Card.Body >Week 2</Card.Body>
              </div>
              <div style={{display:"flex",flexDirection:"column",flexGrow: 1, padding:20}}>
                <Form.Label>Percentage Complete</Form.Label>
                <ProgressBar now={this.state.questionProgress} label={`${this.state.questionProgress}%`} />
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
              <Card.Body >Question info to be added</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>







      );
  }
  loadClassData() {
    const db = firebase.database();
    const userId = this.state.userId;
    const classId = this.state.classId;

    const classRef = db.ref(`students/${this.state.studentId}/classes/${this.state.classId}`); ////////////Stopped here
    classRef.on("value", (snap) => {

        const classData = snap.val();
        this.setState({
          classData: classData,
        });

    });


  }

  loadStudent() {
    const db = firebase.database();

    const studentsRef = db.ref(`students/${this.state.studentId}`);

    studentsRef.on("value", (snap) => {
      const studentData = snap.val();




      this.setState({
        studentData: studentData,
        loading:false
      });
    });
  }
}

export default withRouter(ClassDetails);
