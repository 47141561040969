import React, {Component} from 'react';
import * as firebase from 'firebase/app';
import "firebase/database";
import {BrowserRouter as Router,Route, Switch, withRouter, Redirect} from 'react-router-dom';
import {Bar, Pie} from 'react-chartjs-2';
import './ClassData.css';
import { Accordion, Card, Modal, ModalTitle, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from "react-bootstrap/ModalHeader";

const randomColor = require('randomcolor');

class ClassData extends Component {
    constructor(props) {
        super(props);

		this.state = {
            teacherId: this.props.userId,
            classId: this.props.match.params.classId,
            classData: null,
            error: false,
            classInformation: null,
            questionSetInformation: null,
            studentNumber: null,
            modalOpen: false,
            name: null,
            classCompletionPercent: null,
            classPerformancePercent:null,
            dueDate: null,
            attempts:null,
            studentsCompleted:[],
            studentsDisplay:[],
            
            additionalInformation: [
                
            ]
        };
        this.handleShowQuestionSetModal = this.handleShowQuestionSetModal.bind(this);
        this.handleHideQuestionSetModal = this.handleHideQuestionSetModal.bind(this);
        this.handleStudentsAttempts = this.handleStudentsAttempts.bind(this);
        this.handleIdToName = this.handleIdToName.bind(this); 
    }
    
    
    componentDidMount() {
        this.loadQuestionSetsData().then(data => {
            this.setState({classData: data});
        }).catch(err => {
            console.error(err);
            this.setState({error: true});
        });

        this.loadClassInformation().then(data => {
            this.setState({classInformation: data});
        }).catch(err => {
            console.error(err);
            this.setState({error: true});
        });

        this.loadQuestionSetInformation().then(data => {
            this.setState({questionSetInformation: data});
        }).catch(err => {
            console.error(err);
            this.setState({error: true});
        });

        this.loadStudentNumber().then(data => {
            this.setState({studentNumber: data});
        }).catch(err => {
            console.error(err);
            this.setState({error: true});
        });
    }
    
    render() {
        if (this.state.error === true) {
            return this.renderErrorContent();
        }
        else if (!this.state.classData || !this.state.classInformation || !this.state.questionSetInformation || !this.state.studentNumber) {
            return this.renderLoadingContent();
        }
        else if (this.state.classData.length === 0) {
            return this.renderNoClassAttemptContent();
        } else {
            return this.renderClassDataContent();
        }
    }

    renderErrorContent() {
        return (
            <div className="class-data center">
                <h1>Sorry!</h1>
                <h2>There was an error loading the data, please try again later...</h2>
            </div>
        );
    }

    renderLoadingContent() {
        return (
            <div className="class-data center">
                Loading...
            </div>
        );
    }

    renderNoClassAttemptContent() {
        return (
            <div className="class-data center">
                <h1>Sorry!</h1>
                <h2>There is no Attempt Data yet...</h2>
            </div>
        );
    }

    renderClassDataContent() {
        return (
            <div>
                <div className="wrapper">
                    <div className="title">
                        <h1>Class Data</h1>
                        <div className="class-name">
                            <h2>{this.state.classInformation.name}</h2>
                            <h3>Class Code: {this.state.classInformation.code}</h3>
                        </div>
                    </div>
                    <div>
                        <h2>Question Set Data</h2>
                        <div className="question-set-wrapper">
                            {this.renderQuestionSetButtons()}
                        </div>
                    </div>
                    <div className="class-data-wrapper">
                        <div>
                            {this.renderClassByNumbers()}
                        </div>
                        <div className="graph-wrapper">
                            {this.renderClassGraphs()}
                        </div>
                    </div>
                </div>
                
                <div>
                <Modal show={this.state.modalOpen} onHide={this.handleHideQuestionSetModal} size="lg">
                <ModalHeader closeButton onHide={this.handleHideQuestionSetModal}>
                <ModalTitle>
                    {this.state.name}
                </ModalTitle>
                </ModalHeader>
                <ModalBody>
                <h5>Assignment Due Date: {this.state.dueDate}</h5>
                <h5>Percent of class completed: {this.state.classCompletionPercent}%</h5>
                <h5>Average Grade: {this.state.classPerformancePercent}%</h5>
                <h5>Students Completed: {this.state.studentsDisplay}</h5>
            
                </ModalBody>
                </Modal>
                </div>
            </div>)
    }

    renderQuestionSetButtons() {
        const questionSets = [];
        this.state.questionSetInformation.forEach(info => {
            const attempts = this.state.classData.filter(data => data.questionSetID === info.id);
            questionSets.push(
                            <div className="question-set-card" onClick={() => {this.handleName(info.title);
                                                                               this.handleQuestionSetInfo(info);
                                                                               this.handleShowQuestionSetModal();
                                                                               this.handleStudentsAttempts(attempts);
                                                                               }}>
                                <h3>{info.title}</h3>
                                <div class="line"></div>
                                    <div className="question-set-separate">
                                         <div className="question-set-quick">
                                        <h4>Questions: {info.questionNumber}</h4>
                                         <h4>Attempts: {attempts.length}</h4>                        
                                         </div>
                                         See More >
                                    </div>
                            </div>
            )
        });
        return questionSets;
    }

    renderClassByNumbers() {
        const numbers = [];

        // Create sets
        numbers.push(
            <div className="big-number">
                <h1>{this.state.questionSetInformation.length}</h1>
                <h4>Sets</h4>
            </div>
        );

        // Create questions
        let questions = 0;
        this.state.questionSetInformation.forEach(set => {
            questions += set.questionNumber;
        })
        numbers.push(
            <div className="big-number">
                <h1>{questions}</h1>
                <h4>Questions</h4>
            </div>
        );

        // Create students
        numbers.push(
            <div className="big-number">
                <h1>{this.state.studentNumber}</h1>
                <h4>Students</h4>
            </div>
        );

        // Create attempts
        numbers.push(
            <div className="big-number">
                <h1>{this.state.classData.length}</h1>
                <h4>Attempts</h4>
            </div>
        );
        return numbers;
    }

    renderClassGraphs() {
        return (
            <div>
                {this.renderAvgCorrectGraph()}
                {this.renderAttemptsPerSetGraph()}
                {this.renderARGraph()}
            </div>
        );
    }

    renderARGraph() {
        let AR = 0, nonAR = 0;
        this.state.classData.forEach(data => {
            data.ar ? AR++ : nonAR++;
        });
        const options = {
            scales: {
                yAxes: [{
                    ticks: {beginAtZero: true}
                }]
            }
        };
        const data = {
            labels: ['AR', 'Non-AR'],
            datasets: [{
                label: 'Number of attempts',
                data: [AR, nonAR],
                backgroundColor: randomColor({
                    hue: 'blue',
                    count: 2
                }),
            }]
        };
        return (
            <div className="graph">
                <h3>Total Number of AR vs Non-AR Attempts</h3>
                <Bar data={data} options={options}/>
            </div>
        );
    }

    renderAvgCorrectGraph() {
        const setInfoNames = [];
        const setData = [];
        this.state.questionSetInformation.forEach(info => {
            const attempts = this.state.classData.filter(data => data.questionSetID === info.id);
            let correct = 0;
            let questions = 0;
            if (attempts.length > 0) {
                attempts.forEach(a => {
                    if (a.questions)
                        a.questions.forEach(q => {
                            if (q.correct)
                                correct++;
                            questions++;
                        });
                });
                setInfoNames.push(info.title);
                setData.push((correct/questions)*100);
            }
        });

        const options = {
            scales: {
                yAxes: [{
                    ticks: {beginAtZero: true}
                }]
            }
        };

        const data = {
            labels: setInfoNames,
            datasets: [{
                label: 'Average number correct',
                data: setData,
                backgroundColor: randomColor({
                    hue: 'blue',
                    count: setData.length
                }),
            }]
        };

        return (
            <div className="graph">
                <h3>Average Number of Questions Correct</h3>
                <Bar data={data} options={options}/>
            </div>);
    }

    renderAttemptsPerSetGraph() {
        const setInfoNames = [];
        const setData = [];
        this.state.questionSetInformation.forEach(info => {
            const attempts = this.state.classData.filter(data => data.questionSetID === info.id).length;
            if (attempts > 0) {
                setInfoNames.push(info.title);
                setData.push(attempts);
            }
        });

        const data = {
            labels: setInfoNames,
            datasets: [{
                label: 'Number of attempts',
                data: setData,
                backgroundColor: randomColor({
                    hue: 'blue',
                    count: setData.length
                }),
            }]
        };

        return (
            <div className="graph">
                <h3>Total Number of Attempts</h3>
                <Pie data={data}/>
            </div>
        );
    }

    /**
     * Returns the name and code of the class
     */
    async loadClassInformation() {
        const db = firebase.database();
        const classNameRef = db.ref(`teachers/${this.state.teacherId}/classes/${this.state.classId}`);
        let snap = await classNameRef.once('value');
        let val = snap.val();
        let name, code;
        if (val) {
            name = val.name;
        }
        const accessCodeRef = db.ref(`accessCodes`).orderByChild('classId').equalTo(this.state.classId);
        snap = await accessCodeRef.once('value');
        val = snap.val();
        if (val) {
            Object.keys(val).forEach(k => {
                code = k;
            });
        }
        return {name, code};
    }

    /**
     * Loads all of the attempt data for the class
     */
    async loadQuestionSetsData() {
        const setIds = await this.loadQuestionSetIds();
        return await this.loadTotalClassAttemptData(setIds);
    }

    async loadQuestionSetInformation() {
        const db = firebase.database();
        const questionSetDataRef = db.ref(`teachers/${this.state.teacherId}/classes/${this.state.classId}/questionSets`);
        const snap = await questionSetDataRef.once('value');
        const val = snap.val();
        const info = [];
        if (val) {
            Object.keys(val).forEach(key => {
                let questions = 0;
                Object.keys(val[key].questions).forEach(k => questions++);
                info.push({
                    id: key,
                    title: val[key].title,
                    questionNumber: questions,
                    classCompletionPercent:val[key].classCompletionPercentage,
                    classPerformancePercent:val[key].classPerformancePercentage,
                    dueDate:val[key].dueDate,
                });
            });
            console.log(snap)
        }
        return info;
    }

    /**
     * Loads question set ids for a given class
     */
    async loadQuestionSetIds() {
        const db = firebase.database();
        const questionSetDataRef = db.ref(`teachers/${this.state.teacherId}/classes/${this.state.classId}/questionSets`);
        const snap = await questionSetDataRef.once('value');
        const val = snap.val();
        const questionSetIds = [];
        if (val) {
            Object.keys(val).forEach(key => {
                questionSetIds.push(key);
            });
        }
        return questionSetIds;
    }

    /**
     * Loads attempt data from a list of set ids
     * @param {*} setIds 
     */
    async loadTotalClassAttemptData(setIds) {
        const db = firebase.database();
        const attempts = [];
        for (let i = 0; i < setIds.length; i++) {
            const attemptDataRef = db.ref(`attempts`).orderByChild('questionSetID').equalTo(setIds[i]);
            const snap = await attemptDataRef.once('value');
            const val = snap.val();
            if (val) {
                Object.keys(val).forEach(key => {
                    attempts.push(val[key]);
                });
            }
        }
        return attempts;
    }

    /**
     * Loads the number of students in the class
     */
    async loadStudentNumber() {
        const db = firebase.database();
        const classRef = db.ref(`teachers/${this.state.teacherId}/classes/${this.state.classId}`);
        const snap = await classRef.once("value");
        const val = snap.val();

        if (val) {
            return val.students.length;
        } else {
            return 0;
        }
    }
    handleShowQuestionSetModal() {
        this.setState({
          modalOpen: true,
        });
      }
    
      handleHideQuestionSetModal(info) {
        this.setState({
          modalOpen: false,
          selectedAttempt: info
        });
      }
      handleName(val){
          this.setState({
              name: val
          });
      }
      handleQuestionSetInfo(val){
          this.setState({
            classCompletionPercent: val.classCompletionPercent,
            classPerformancePercent:val.classPerformancePercent,
            dueDate: val.dueDate,
          })
      }
    
      handleStudentsAttempts(val){
          let student = [];
                for(let i =0; i <val.length; i++){
                if(!student.includes(val[i].studentID)){
                student.push(val[i].studentID)
                }
        }
            this.setState({
                studentsDisplay: []
            });
            this.handleIdToName(student);
        }
       handleIdToName(student){
            let display =[]
            const db = firebase.database();
            const classRef = db.ref(`students`);
            classRef.on("value", (snap) =>{
            const val = snap.val();
            for(let i=0; i<student.length; i++)
            {
                if (val[student[i]]){
                display.push(val[student[i]].displayName);
                }
            }
            this.setState({
                studentsDisplay: display
            });

            });
            
                
            }   
}
    


export default withRouter(ClassData);